<template>
  <div>
    <b-row>
      <b-col md="10" xl="10">
        <b-card class="justify-content-md-center">
          <b-form-textarea
            placeholder="Escreva a o termo aqui..."
            v-model="dateTerm.terms"
            rows="22"
          />
              <!-- <quill-editor
                v-model="dateTerm.terms"
                :options="snowOption"
              /> -->
          <b-row>
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            block
            class="m-1"
            @click="storeTerm"
          >
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            block
            class="m-1"
            type="reset"
          >
            Apagar
          </b-button>
          </b-row>
          
        </b-card>
      </b-col>
      <b-col md="2" xl="2">
        <b-row>
          <b-card class="justify-content-md-center term-style overflow-auto">
            <h4 class="text-center">Termo atual</h4>
            <hr />
            <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
              <p class="text-justify" v-if="term.data == null">Nenhum termo cadastrado.</p>
              <p class="text-justify" v-else>{{ term.data.terms }}</p>
            </b-overlay>
          </b-card>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateTerm: {
        terms: null,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapState("term", ["term"]),
  },
  methods: {
    ...mapActions("term", ["fetchLestTerm", "store"]),
    storeTerm() {
      this.store({
        ...this.dateTerm,
      });
    },
  },
  created() {
    this.fetchLestTerm();
  },
  watch: {
    term() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.term-style {
  width: 100%;
  height: 682px;
}
</style>

